import {DisplayIconComponent} from './display-icon/display-icon.component';
import {IconArrowDownComponent} from './icon-arrow-down/icon-arrow-down.component';
import {IconArrowLeftComponent} from './icon-arrow-left/icon-arrow-left.component';
import {IconArrowRightComponent} from './icon-arrow-right/icon-arrow-right.component';
import {IconArrowUpComponent} from './icon-arrow-up/icon-arrow-up.component';
import {IconBarcodeComponent} from './icon-barcode/icon-barcode.component';
import {IconBackspaceOutlineComponent} from './icon-backspace-outline/icon-backspace-outline.component';
import {IconBellOutlineComponent} from './icon-bell-outline/icon-bell-outline.component';
import {IconBugComponent} from './icon-bug/icon-bug.component';
import {IconCalendarComponent} from './icon-calendar/icon-calendar.component';
import {IconCalendarClearComponent} from './icon-calendar-clear/icon-calendar-clear.component';
import {IconCartComponent} from './icon-cart/icon-cart.component';
import {IconChevronDownComponent} from './icon-chevron-down/icon-chevron-down.component';
import {IconChevronLeftComponent} from './icon-chevron-left/icon-chevron-left.component';
import {IconChevronRightComponent} from './icon-chevron-right/icon-chevron-right.component';
import {IconChevronUpComponent} from './icon-chevron-up/icon-chevron-up.component';
import {IconClipboardComponent} from './icon-clipboard/icon-clipboard.component';
import {IconCreateComponent} from './icon-create/icon-create.component';
import {IconDownloadComponent} from './icon-download/icon-download.component';
import {IconDownloadOutlineComponent} from './icon-download-outline/icon-download-outline.component';
import {IconEyeOutlineComponent} from './icon-eye-outline/icon-eye-outline.component';
import {IconEyeOffOutlineComponent} from './icon-eye-off-outline/icon-eye-off-outline.component';
import {IconFileTrayStackedComponent} from './icon-file-tray-stacked/icon-file-tray-stacked.component';
import {IconGoogleDinosaurComponent} from './icon-google-dinosaur/icon-google-dinosaur.component';
import {IconHelpCircleOutlineComponent} from './icon-help-circle-outline/icon-help-circle-outline.component';
import {
  IconInfoSlabCircleOutlineComponent
} from './icon-info-slab-circle-outline/icon-info-slab-circle-outline.component';
import {IconHomeComponent} from './icon-home/icon-home.component';
import {IconKeyComponent} from './icon-key/icon-key.component';
import {IconLogInComponent} from './icon-log-in/icon-log-in.component';
import {IconMailComponent} from './icon-mail/icon-mail.component';
import {IconMenuComponent} from './icon-menu/icon-menu.component';
import {IconNewBoxComponent} from './icon-new-box/icon-new-box.component';
import {IconNotEqualVariantComponent} from './icon-not-equal-variant/icon-not-equal-variant.component';
import {IconPencilComponent} from './icon-pencil/icon-pencil.component';
import {IconPencilOffComponent} from './icon-pencil-off/icon-pencil-off.component';
import {IconPersonComponent} from './icon-person/icon-person.component';
import {IconPieChartComponent} from './icon-pie-chart/icon-pie-chart.component';
import {IconPintComponent} from './icon-pint/icon-pint.component';
import {IconPlanetComponent} from './icon-planet/icon-planet.component';
import {IconPriceTagsComponent} from './icon-price-tags/icon-price-tags.component';
import {IconSettingsComponent} from './icon-settings/icon-settings.component';
import {IconSortComponent} from './icon-sort/icon-sort.component';
import {IconTerminalComponent} from './icon-terminal/icon-terminal.component';
import {IconTodayComponent} from './icon-today/icon-today.component';

export const DISPLAY_ICON = DisplayIconComponent;
export const ICON_ARROW_DOWN = IconArrowDownComponent;
export const ICON_ARROW_LEFT = IconArrowLeftComponent;
export const ICON_ARROW_RIGHT = IconArrowRightComponent;
export const ICON_ARROW_UP = IconArrowUpComponent;
export const ICON_BARCODE = IconBarcodeComponent;
export const ICON_BACKSPACE_OUTLINE = IconBackspaceOutlineComponent;
export const ICON_BELL_OUTLINE = IconBellOutlineComponent;
export const ICON_BUG = IconBugComponent;
export const ICON_CALENDAR = IconCalendarComponent;
export const ICON_CALENDAR_CLEAR = IconCalendarClearComponent;
export const ICON_CART = IconCartComponent;
export const ICON_CHEVRON_DOWN = IconChevronDownComponent;
export const ICON_CHEVRON_LEFT = IconChevronLeftComponent;
export const ICON_CHEVRON_RIGHT = IconChevronRightComponent;
export const ICON_CHEVRON_UP = IconChevronUpComponent;
export const ICON_CLIPBOARD = IconClipboardComponent;
export const ICON_CREATE = IconCreateComponent;
export const ICON_DOWNLOAD = IconDownloadComponent;
export const ICON_DOWNLOAD_OUTLINE = IconDownloadOutlineComponent;
export const ICON_EYE_OFF_OUTLINE = IconEyeOffOutlineComponent;
export const ICON_EYE_OUTLINE = IconEyeOutlineComponent;
export const ICON_FILE_TRAY_STACKED = IconFileTrayStackedComponent;
export const ICON_GOOGLE_DINOSAUR = IconGoogleDinosaurComponent;
export const ICON_HELP_CIRCLE_OUTLINE = IconHelpCircleOutlineComponent;
export const ICON_INFO_SLAB_CIRCLE_OUTLINE = IconInfoSlabCircleOutlineComponent;
export const ICON_HOME = IconHomeComponent;
export const ICON_KEY = IconKeyComponent;
export const ICON_LOG_IN = IconLogInComponent;
export const ICON_MAIL = IconMailComponent;
export const ICON_MENU = IconMenuComponent;
export const ICON_NEW_BOX = IconNewBoxComponent;
export const ICON_NOT_EQUAL_VARIANT = IconNotEqualVariantComponent;
export const ICON_PENCIL = IconPencilComponent;
export const ICON_PENCIL_OFF = IconPencilOffComponent;
export const ICON_PERSON = IconPersonComponent;
export const ICON_PIE_CHART = IconPieChartComponent;
export const ICON_PINT = IconPintComponent;
export const ICON_PLANET = IconPlanetComponent;
export const ICON_PRICE_TAGS = IconPriceTagsComponent;
export const ICON_SETTINGS = IconSettingsComponent;
export const ICON_SORT = IconSortComponent;
export const ICON_TERMINAL = IconTerminalComponent;
export const ICON_TODAY = IconTodayComponent;

export const SHARED_ICONS = [
  DISPLAY_ICON,
  ICON_ARROW_DOWN,
  ICON_ARROW_LEFT,
  ICON_ARROW_RIGHT,
  ICON_ARROW_UP,
  ICON_BARCODE,
  ICON_BACKSPACE_OUTLINE,
  ICON_BELL_OUTLINE,
  ICON_BUG,
  ICON_CALENDAR,
  ICON_CALENDAR_CLEAR,
  ICON_CART,
  ICON_CHEVRON_DOWN,
  ICON_CHEVRON_LEFT,
  ICON_CHEVRON_RIGHT,
  ICON_CHEVRON_UP,
  ICON_CLIPBOARD,
  ICON_CREATE,
  ICON_DOWNLOAD,
  ICON_DOWNLOAD_OUTLINE,
  ICON_EYE_OFF_OUTLINE,
  ICON_EYE_OUTLINE,
  ICON_FILE_TRAY_STACKED,
  ICON_GOOGLE_DINOSAUR,
  ICON_HELP_CIRCLE_OUTLINE,
  ICON_INFO_SLAB_CIRCLE_OUTLINE,
  ICON_HOME,
  ICON_KEY,
  ICON_LOG_IN,
  ICON_MAIL,
  ICON_MENU,
  ICON_NEW_BOX,
  ICON_NOT_EQUAL_VARIANT,
  ICON_PENCIL,
  ICON_PENCIL_OFF,
  ICON_PERSON,
  ICON_PIE_CHART,
  ICON_PINT,
  ICON_PLANET,
  ICON_PRICE_TAGS,
  ICON_SETTINGS,
  ICON_SORT,
  ICON_TERMINAL,
  ICON_TODAY,
];
