import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {MessagesModalPageRoutingModule} from './messages-modal-routing.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DefaultBodyComponent} from './messages-modal.page';
import {
  MsgUserSignupNotificationComponent
} from './msg-user-signup-notification/msg-user-signup-notification.component';
import {AoReattemptModalComponent} from './ao-reattempt-modal/ao-reattempt-modal.component';
import {FailedOrdersAlertComponent} from './failed-orders-alert/failed-orders-alert.component';
import {MsgAoResponseComponent} from './msg-ao-response/msg-ao-response.component';
import {MsgApiResponseComponent} from './msg-api-response/msg-api-response.component';
import {StockHistoryLogComponent} from './stock-history-log/stock-history-log.component';
import {StockUpdatesViewerComponent} from './stock-updates-viewer/stock-updates-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MessagesModalPageRoutingModule,
    MatTooltipModule
  ],
  declarations: [
    DefaultBodyComponent,
    AoReattemptModalComponent,
    FailedOrdersAlertComponent,
    MsgAoResponseComponent,
    MsgApiResponseComponent,
    MsgUserSignupNotificationComponent,
    StockHistoryLogComponent,
    StockUpdatesViewerComponent
  ],
  exports: [
    DefaultBodyComponent,
    AoReattemptModalComponent,
    FailedOrdersAlertComponent,
    MsgAoResponseComponent,
    MsgApiResponseComponent,
    MsgUserSignupNotificationComponent,
    StockHistoryLogComponent,
    StockUpdatesViewerComponent
  ]
})
export class MessagesModalPageModule {
}
