import {IonAccordionGroup} from '@ionic/angular';
import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {IPageObj} from '../../../../shared-utilities/models-old/page/page';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {UserAccess, UserObj} from '../../../../shared-utilities/models-old/datastructures';
import {Router} from '@angular/router';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {Store} from '@ngrx/store';
import {setCurrentPageAndTab, setITA} from '../../store/core.actions';
import {AccessFlags, pageRules, RuleHumanID} from '../../../../shared-utilities/models-old/utils-old/rule-structure';
import {selectITA} from "../../store/core.selectors";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @ViewChildren(IonAccordionGroup) accordionGroups: QueryList<IonAccordionGroup>;

  @Input() selectedPage?: string[] = [];
  @Input() defaultOpen?: boolean;
  @Input() pages: IPageObj[] = [];
  @Input() subPages? = {};
  @Input() subNav = false;

  @Output() sizeChange: EventEmitter<string> = new EventEmitter<string>();

  userPages: IPageObj[] = [];
  username: string;
  userID: string;
  icon = Icons;
  showFullLogo = false;
  clickCount = 0;

  private menuExpanded = false;
  private menuFinishedExpanding = false;
  private menuCompactTimeout: any;
  private userAccess: UserAccess;

  constructor(
    private router: Router,
    private firebase: FirebaseService,
    private fireAuth: FireAuthService,
    private readonly store: Store,
  ) {
  }

  get isMenuExpanded(): boolean {
    return this.menuExpanded;
  }

  get hasMenuFinishedExpanding(): boolean {
    return this.menuFinishedExpanding;
  }

  ngOnInit(): void {
    this.firebase.userObj.subscribe((obj: UserObj): void => {
      this.setUserObj(obj);
    });

    this.fireAuth.userAccess.subscribe((ua: UserAccess): void => {
      this.setUserAccess(ua);
    });

    this.calledAfterInit();
  }

  increaseClickCount(): void {
    this.clickCount++;
  }

  setUserObj(obj: UserObj): void {
    if (obj) {
      this.username = obj.userName;
      this.userID = obj.id;
    }
    this.setPages();
  }

  setUserAccess(ua: UserAccess): void {
    this.userAccess = ua;
    this.store.dispatch(setITA({ita: ua?.['isTechoAdmin']}))
    this.setPages();
  }

  calledAfterInit(): void {
    this.menuFinishedExpanding = this.menuExpanded;
    if (!this.menuExpanded) {
      this.onMouseLeftMenu();
    }
    this.menuFinishedExpanding = false;
    if (!this.selectedPage) {
      this.router.navigate(['home']).then();
    }
  };

  onToggleAccordion(acc?: string): void {
    if (this.accordionGroups) {
      this.accordionGroups.forEach((accordionGroup: IonAccordionGroup): void => {
        const nativeElement: IonAccordionGroup = accordionGroup;
        nativeElement.value = nativeElement.value ? undefined : acc;
      });
    }
  }

  onNavigate(path: string, tab?: string): void {
    this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: path, currentTab: tab ? tab : null}}));
    this.selectedPage = [`${path}`, `${tab}`];
  }

  resetTimeOut(): void {
    if (this.menuCompactTimeout) {
      clearTimeout(this.menuCompactTimeout);
      this.menuCompactTimeout = null;
      this.menuFinishedExpanding = this.menuExpanded;
    }
  }

  onMouseOverMenu(): void {
    if (this.isMenuExpanded) {
      this.menuFinishedExpanding = true;
    }
  }

  onMouseLeftMenu(timeout: number = 1000): void {
    if (!this.menuCompactTimeout) {
      this.menuCompactTimeout = setTimeout(() => {
        if (this.menuExpanded) {
          this.onToggleMenu();
        }
      }, timeout);
    }
  }

  onToggleMenu(): void {
    this.clickCount = 0;
    this.menuExpanded = !this.menuExpanded;
    this.onToggleAccordion();
    setTimeout(() => {
      this.menuFinishedExpanding = this.menuExpanded;
    }, 500);
    setTimeout(() => {
      this.showFullLogo = !this.showFullLogo;
    }, 100);
    this.emitSizeChange();
  }

  expandMenuOnAccClick(acc: string): void {
    if (!this.isMenuExpanded) {
      this.onToggleMenu();
      this.onToggleAccordion(acc);
    }
  }

  private setPages(): void {
    // Todo: refactor at some point
    if (this.userAccess && this.userID) {
      this.userPages = this.pages.filter((po: IPageObj): boolean => {
        const rules = pageRules(po.page);
        return rules
          ? rules.some((rule: RuleHumanID | AccessFlags) =>
            this.userAccess.storeList.some((storeID: string) =>
              this.fireAuth.hasAccess(storeID, {ruleID: rule})))
          : false;
      });
      if (!this.subNav) {
        this.userPages.unshift({page: 'home', text: 'Home', icon: Icons.home});
      }
    } else {
      this.userPages = [this.pages.find((po: IPageObj) => po.page === 'login')];
    }
    if (
      (this.userID === 'studiosfjr@gmail.com') ||
      // (this.userID === 'claydenburger@gmail.com') ||
      (this.userID === 'baileyhassall@gmail.com')
    ) {
      this.userPages = this.userPages.concat(this.pages.slice(this.pages.length - 3));
    }
  }

  private emitSizeChange() {
    this.sizeChange.emit(this.menuExpanded ? 'expand' : 'contract');
  }

}
