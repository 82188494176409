import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';

@Component({
  selector: 'app-freshness-explanation',
  templateUrl: './freshness-explanation.component.html',
  styleUrls: ['./freshness-explanation.component.scss'],
})
export class FreshnessExplanationComponent implements OnInit {

  imageUrl: string | undefined;
  isLoading = true;

  constructor(
    private modalControl: ModalController,
    private firebase: FirebaseService
  ) { }

  ngOnInit() {
    this.firebase.getImageUrl('/site_media/help/data_freshness/mgt_cloud_flow_chart.svg').subscribe((url: string) => {
      this.imageUrl = url;
    });
  }

  onImageLoad() {
    this.isLoading = false;
  }
  close(){
    this.modalControl.dismiss().then();
  }
}
