import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {MenuController} from '@ionic/angular';
import {PAGES, PAGES_SUB} from './shared-utilities/models-old/page/pages-const';
import {INavigationPage, IPageObj, ISubPageObj} from './shared-utilities/models-old/page/page';
import {Store} from '@ngrx/store';
import {
  getUser,
  setIsModuleLoading
} from './features-as-modules/feature-core/store/core.actions';
import {
  selectCurrentPageAndTab,
  selectIsModuleLoading
} from './features-as-modules/feature-core/store/core.selectors';
import {FirebaseService} from './shared-utilities/services-old/firebase.service';
import {InactivityService} from './shared-utilities/services-old/inactivity.service';
import {initDepartmentFunctions} from './shared-utilities/functions-old/department-functions';

import {
  AnimationDefault,
  AnimationDirection,
  Animations,
  IAnimation
} from './shared/shared-components/utils/animations/animation';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {

  get currentSelectedPage(): string[] {
    return [this.currentSelectedPageObj.currentPage, this.currentSelectedPageObj.currentTab];
  }

  currentNavigation$: Observable<{ currentSelectedPage: INavigationPage; }>;
  isModuleLoading: Observable<boolean>;

  readonly menuPages: IPageObj[] = PAGES;
  readonly menuPagesSubs: ISubPageObj = PAGES_SUB;
  readonly animationLogo: IAnimation = {
    ...AnimationDefault,
    animation: Animations.CIRCLE_DISAPPEAR_AND_REAPPEAR,
    direction: AnimationDirection.REVERSE,
    id: 'app-component-animation-logo'
  }

  private currentSelectedPageObj: INavigationPage;
  private isNavigationInProgress = false;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private menuControl: MenuController,
    private inactivityService: InactivityService,
    private readonly store: Store,
  ) {
    initDepartmentFunctions(firebaseService);
    this.inactivityService.startWatching();
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: RouterEvent): void => {
      this.dispatchRouterEvents(event);
    });
    // Getting the user
    this.store.dispatch(getUser());
    // Getting and setting navigation
    this.isModuleLoading = this.store.select(selectIsModuleLoading('home'));
    this.currentNavigation$ = this.store.select(selectCurrentPageAndTab);
    this.currentNavigation$.subscribe((navigation: { currentSelectedPage: INavigationPage; }): void => {
      this.setNavigation(navigation);
    });
  }

  async onLogin(): Promise<void> {
    await this.onCloseMenu();
    this.router.navigate(['login'])
      .then()
      .catch((error): void => {
          console.error(`Navigation failed to login.`, error);
        }
      );
  }

  async onCloseMenu(): Promise<void> {
    await this.menuControl.close('main-menu');
  }

  changeMenuSize(value: string): void {
    if (value === 'expand') {
      document.querySelector('.side-nav').classList.add('expanded-nav');
    } else {
      document.querySelector('.side-nav').classList.remove('expanded-nav');
    }
  }

  getCurrentPageText(): string {
    const page = this.currentSelectedPage[0];
    const pageObj: IPageObj = this.menuPages.find((p: IPageObj): boolean => p.page === page);
    return pageObj ? pageObj.text : '';
  }

  private setNavigation(selectedStorePageObj: { currentSelectedPage: INavigationPage }): void {
    this.currentSelectedPageObj = selectedStorePageObj.currentSelectedPage;
    const tab = this.currentSelectedPageObj.currentTab;
    const queryParams = this.currentSelectedPageObj.currentTab ? {queryParams: {tab}} : {};
    this.router.navigate([this.currentSelectedPageObj.currentPage], queryParams)
      .then()
      .catch((error): void => {
          console.error(`Navigation failed to ${this.currentSelectedPageObj.currentPage}.`, error);
        }
      );
  }

  private dispatchRouterEvents(event: RouterEvent): void {
    if (event instanceof NavigationStart && !this.isNavigationInProgress) {
      this.isNavigationInProgress = true;
      this.store.dispatch(setIsModuleLoading({event, isLoading: true, instance: 'NavigationStart'}));
    }
    if (event instanceof NavigationEnd && this.isNavigationInProgress) {
      this.store.dispatch(setIsModuleLoading({event, isLoading: false, instance: 'NavigationEnd'}));
      this.isNavigationInProgress = false;
    }
  }

}
