export enum Icons {
  arrowDown= 'arrowDown',
  arrowLeft= 'arrowLeft',
  arrowRight= 'arrowRight',
  arrowUp= 'arrowUp',
  backSpaceOutline = 'backSpaceOutline',
  barcode = 'barcode',
  bellOutline = 'bellOutline',
  bugOutline = 'bug-outline',
  calendar = 'calendar',
  calendarClear = 'calendarClear',
  cart = 'cart',
  chevronDown = 'chevronDown',
  chevronLeft = 'chevronLeft',
  chevronRight = 'chevronRight',
  chevronUp = 'chevronUp',
  clipboard = 'clipboard',
  create = 'create',
  download = 'download',
  downloadOutline = 'downloadOutline',
  fileTrayStacked = 'fileTrayStacked',
  eyeOffOutline = 'eyeOffOutline',
  eyeOutline = 'eyeOutline',
  googleDinosaur = 'googleDinosaur',
  helpCircleOutline = 'helpCircleOutline',
  infoSlabCircleOutline = 'infoSlabCircleOutline',
  home = 'home',
  key = 'key',
  logIn = 'logIn',
  mail = 'mail',
  menu = 'menu',
  newBox = 'newBox',
  notEqualVariant = 'notEqualVariant',
  pencil = 'pencil',
  pencilOff = 'pencil-off',
  person = 'person',
  pieChart = 'pieChart',
  pint = 'pint',
  planet = 'planet',
  priceTags = 'priceTags',
  settings = 'settings',
  sort = 'sort',
  terminal = 'terminal',
  today = 'today',
}
