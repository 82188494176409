import {createAction, props} from '@ngrx/store';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {LineColour, StockItem, Supplier} from '../../../shared-utilities/models-old/datastructures';
import {FbQuery} from '../../../shared-utilities/services-old/firebase.service';
import {ColDef} from 'ag-grid-community';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';

// ====================================================================================================
// Get Line Colours
// ====================================================================================================
export const getLineColours = createAction(
  '[NGP Report] Get Line Colours',
  props<{ pageStoreDocument: string }>()
);

export const getLineColoursSuccess = createAction(
  '[NGP Report] Get Line Colours Success',
  props<{ lineColours: LineColour; selectedStore: IStore }>()
);

export const getLineColoursFailure = createAction(
  '[NGP Report] Get Line Colours Failure',
  props<{ error: IError }>()
);

export const setIsLineColoursLoading = createAction(
  '[NGP Report] Set Is Line Colours Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Get Stock Items
// ====================================================================================================
export const getStockItem = createAction(
  '[NGP Report] Get Stock Item',
  props<{
    store: IStore;
    firebaseQueries: FbQuery[];
    options: {
      idField: string;
      cast?: boolean;
      objectify?: boolean;
    };
  }>()
);

export const getStockItemWithExistingSuppliers = createAction(
  '[NGP Report] Get Stock Item With Existing Suppliers',
  props<{
    store: IStore;
    firebaseQueries: FbQuery[];
    options: {
      idField: string;
      cast?: boolean;
      objectify?: boolean;
    };
    suppliers: { [key: string]: Supplier }
  }>()
);

export const getStockItemWithoutExistingSuppliers = createAction(
  '[NGP Report] Get Stock Item Without Existing Suppliers',
  props<{
    store: IStore;
    firebaseQueries: FbQuery[];
    options: {
      idField: string;
      cast?: boolean;
      objectify?: boolean;
    };
  }>()
);

export const getStockItemSuccess = createAction(
  '[NGP Report] Get Stock Item Success',
  props<{ stockItems: StockItem[] | StockItem[][] | any[]; store: IStore }>()
);

export const getStockItemFailure = createAction(
  '[NGP Report] Get Stock Item Failure',
  props<{ error: IError }>()
);

export const setIsStockItemLoading = createAction(
  '[NGP Report] Set Stock Item Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Set Filters and Tools
// ====================================================================================================
export const setNGPReportFiltersAndTools = createAction(
  '[NGP Report] Set NGP Report Filters and Tools',
  props<{ filtersAndTools: FiltersAndTools }>()
);

export const setNewlyAddedItemsVisibility = createAction(
  '[NGP Report] Set Newly Added Items Visibility'
);

export const setNewlyAddedItemsVisibilityWithStoreID = createAction(
  '[NGP Report] Set Newly Added Items Visibility with Store ID',
  props<{ storeID: string }>()
);


// ====================================================================================================
// Update NGP Report Item
// ====================================================================================================
export const updateSingleNGPReport = createAction(
  '[NGP Report] Update Single NGP Report',
  props<{ ngpReport: NGPReport}>()
);

export const updateSingleNGPReportWithStoreId = createAction(
  '[NGP Report] Update Single NGP Report At StoreID',
  props<{ ngpReport: NGPReport, storeID: string }>()
);

export const updateAllNGPReports = createAction(
  '[NGP Report] Update Multiple NGP Reports',
  props<{ ngpReports: NGPReport[] }>()
);

export const updateAllNGPReportsWithStoreID = createAction(
  '[NGP Report] Update Multiple NGP Reports With Store ID',
  props<{ ngpReports: NGPReport[], storeID: string }>()
);

// ====================================================================================================
// Set Grid Column Definitions
// ====================================================================================================
export const setNGPReportGridColDefs = createAction(
  '[NGP Report] Set NGP Report Grid Column Definition',
  props<{ colDefs: ColDef[] }>()
);

export const setNGPReportGridColDefsWithStoreID = createAction(
  '[NGP Report] Set NGP Report Grid Column Definitions with Store ID',
  props<{ colDefs: ColDef[]; storeID: string }>()
);


// ====================================================================================================
// Set Grid Menu Action
// ====================================================================================================
export const setNGPReportMenuActions = createAction(
  '[NGP Report] Set NGP Report Menu Actions per Column',
  props<{ colId: string; typeChange: string; menuData: any }>()
);


export const setNGPReportMenuActionsWithStore = createAction(
  '[NGP Report] Set NGP Report Menu Actions per Column with Store Details',
  props<{ store: IStore; colId: string; typeChange: string; menuData: any }>()
);

//===============================================================================================================
// Set Visible Fields
//===============================================================================================================
export const setNGPReportVisibility = createAction(
  '[NGP Report] Set NGP Report Visible Fields',
  props<{ colDef: ColDef[]; menuData: any }>()
);

export const setNGPReportVisibilityWithStoreID = createAction(
  '[NGP Report] Set NGP Report Visibility with Store ID',
  props<{ colDef: ColDef[]; menuData: any; storeID: string }>()
);


export const updateSingleVisibleField = createAction(
  '[NGP Report] Update Single Visible Field',
  props<{ colId: string; menuData: any }>()
);

//===============================================================================================================
// Get Store Departments
//===============================================================================================================
export const getStoreDepartments = createAction(
  '[NGP Report] Get Store Departments',
  props<{ storeId: string }>()
);

export const getStoreDepartmentsSuccess = createAction(
  '[NGP Report] Get Store Departments Success',
  props<{ storeId: string; departments: any }>()
);

export const getStoreDepartmentsFailure = createAction(
  '[NGP Report] Get Store Departments Failure',
  props<{ error: any }>()
);

export const setIsStoreDepartmentsLoading = createAction(
  '[NGP Report] Set Is Store Departments Loading',
  props<{ isLoading: boolean }>()
);

//===============================================================================================================
// Get Store Suppliers
//===============================================================================================================
export const getStoreSuppliersByStoreId = createAction(
  '[NGPReport] Get Store Suppliers By Store Id',
  props<{ storeID: string }>()
);

export const getStoreSuppliersByStoreIdSuccess = createAction(
  '[NGPReport] Get Store Suppliers By Store Id Success',
  props<{ suppliers: { [key: string]: Supplier }; storeID: string }>()
);

export const getStoreSuppliersByStoreIdFailure = createAction(
  '[NGPReport] Get Store Suppliers By Store Id Failure',
  props<{ error: IError }>()
);

export const setIsStoreSuppliersByStoreIdLoading = createAction(
  '[NGPReport] Set Is Store Suppliers By Store Id Loading',
  props<{ isLoading: boolean }>()
);

//===============================================================================================================
// Remove Selected Items From NGP Report
//===============================================================================================================
export const removeSelectedNgpReports = createAction(
  '[NGP Report] Remove Selected NGP Reports',
  props<{ selectedReports: NGPReport[], itemsToUpdate: { [code: string]: { o: StockItem; n: StockItem } } }>()
);

export const removeSelectedNgpReportsWithStoreID = createAction(
  '[NGP Report] Remove Selected NGP Reports with Store ID',
  props<{ selectedReports: NGPReport[]; storeID: string }>()
);



