import {Component, Input} from '@angular/core';
import {Icons} from '../../icons';

/**
 * DisplayIconComponent renders a icon depending on set inputs from a list of pre-selectable icons.
 *
 * @member {Icons} icon The selected icon that will be displayed.
 * @member {string} tooltip The selected icon's tooltip that will be displayed on icon hover.
 * @member {boolean} border Is a border visible around the icon.
 * @member {'green' | 'red' | 'yellow' | 'white'} colour What colour should the icon be.
 * @member {string[]} classList() This gets a list of classes according to inputs.
 *
 */
@Component({
  selector: 'app-display-icon',
  templateUrl: './display-icon.component.html',
  styleUrls: ['./display-icon.component.scss'],
})
export class DisplayIconComponent {

  /**
   * Get a list of classes according to inputs: border and colour.
   * Examples: `icon-green`, `border-green`
   *
   * @member {string[]} classList() This gets a list of classes according to inputs.
   * @returns A list of classes in a string array.
   */
  get classList(): string[] {
    return [
      `icon-${this.colour}`,
      `${this.border ? 'border-' + this.colour : ''}`
    ];
  }

  /**
   * Set the icon to be displayed from a list of Icons enum.
   *
   * @member {Icons} icon The selected icon that will be displayed.
   */
  @Input() icon: Icons;

  /**
   * Set the tooltip for icon to be displayed - can be omitted.
   *
   * @member {string} tooltip The selected icon's tooltip that will be displayed on icon hover.
   */
  @Input() tooltip: string;

  /**
   * Set whether icon should be displayed with a border.
   *
   * @member {boolean} border Is a border visible around the icon.
   * @default false
   */
  @Input() border = false;

  /**
   * Set the colour of the selected icon.
   *
   * @member {'green' | 'red' | 'yellow' | 'white'} colour What colour should the icon be.
   * @default white
   */
  @Input() colour: 'green' | 'red' | 'yellow' | 'white' = 'white';

  protected readonly icons = Icons;

}
