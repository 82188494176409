import {Component, Input, OnInit, Optional} from '@angular/core';
import {IonRouterOutlet, ModalController, PopoverController} from '@ionic/angular';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {IUser} from '../../../../shared-utilities/models-old/user/IUser';
import {MessagesService} from '../../../../shared-utilities/services-old/message-service/messages.service';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {MenuPopupComponent} from '../../../../shared-modules/shared-module/components/menu-popup/menu-popup.component';
import {
  MessagesModalPage
} from '../../../../shared-modules/shared-module/modules-old/messages-modal/messages-modal.page';
import {
  NavigableModalComponent
} from '../../../../shared-modules/shared-module/components/navigable-modal/navigable-modal.component';
import {
  ApplicationSettingsComponent
} from '../../../../shared-modules/shared-module/components/application-settings/application-settings.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  /** @member {string} title Set the title for the shared header component. */
  @Input() title: string;
  user: IUser;
  icons = Icons;

  private messagesCount: number;

  constructor(
    private modalControl: ModalController,
    private firebase: FirebaseService,
    private popoverControl: PopoverController,
    private msgService: MessagesService,
    @Optional() private routerOutlet: IonRouterOutlet,
  ) {
  }

  get userName(): string {
    if (this.user) {
      return this.user.userName;
    }
  }

  get firstLetter(): string {
    return this.user.userName ? this.userName.charAt(0).toUpperCase() : 'M';
  }

  get profilePicture(): string {
    if (this.user.pp) {
      return this.user.pp;
    }
  }

  ngOnInit() {
    if (!this.title) {
      this.title = '';
    }
    this.msgService.numLiveMsgsSub.subscribe((num) => (this.messagesCount = num));

    this.firebase.userObj.subscribe(obj => {
      if (obj) {
        this.user = obj;
      }
    });
  }

  async showMessages() {
    const modal = await this.modalControl.create({
      component: MessagesModalPage,
      componentProps: {}
    });
    modal.onDidDismiss().then((response) => {
    });
    await modal.present();
  }

  numMessages(): number {
    return this.messagesCount;
  }

  async onOpenMenuClick() {
    let pp = [];
    if (this.user && this.user.pp) {
      pp = ['picture', this.user.pp];
    } else {
      pp = ['letter', this.profilePicture];
    }
    const pc = await this.popoverControl.create({
      component: MenuPopupComponent,
      componentProps: {
        user: this.user,
        pp,
      },
      cssClass: 'menu-popup',
      event
    });
    await pc.present().then();
  }

  async settings() {
    const mc = await this.modalControl.create({
      presentingElement: this.routerOutlet == null ? await this.modalControl.getTop() : this.routerOutlet.nativeEl,
      component: NavigableModalComponent,
      componentProps: {root: ApplicationSettingsComponent},
      cssClass: 'settings-modal',
      backdropDismiss: false
    });
    await mc.present();
  }
}
