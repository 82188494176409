import {Component, Inject, Injector, OnInit} from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {BehaviorSubject, Observable} from 'rxjs';
import {MsgAoResponseComponent} from './msg-ao-response/msg-ao-response.component';
import {MsgApiResponseComponent} from './msg-api-response/msg-api-response.component';
import {
  MsgUserSignupNotificationComponent
} from './msg-user-signup-notification/msg-user-signup-notification.component';
import {InboxMessage, INCOMING_MSG_TYPES, StoreInfo} from '../../../../shared-utilities/models-old/datastructures';
import {Store} from '@ngrx/store';
import {DateRangeSelectorComponent} from '../../components/date-range-selector/date-range-selector.component';
import {SelectPopoverComponent} from '../../components/select-popover/select-popover.component';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {AoResponse, MessagesService} from '../../../../shared-utilities/services-old/message-service/messages.service';
import {getUserStores} from '../../../../features-as-modules/feature-core/store/core.actions';
import {selectUserStores} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {leadingZero} from '../../../../shared-utilities/utils-old/formatting';
import {filters2Filter, objLen} from '../../../../shared-utilities/functions-old/object-functions';
import {
  firstDayOMonth,
  firstDayOWeek,
  lastDayOfWeek,
  lastDayOMonth
} from '../../../../shared-utilities/functions-old/date-functions';
import {IStore} from '../../../../shared-utilities/models-old/store/IStore';
import {StoreObject} from '../../../../shared-utilities/models-old/store/store-object';

const RECOMMENDED_MAX = 20 as const;

interface GBA {
  currentWeek: string[];
  currentMonth: string[];
  olderMessages: string[];
}

const RANKED_GBA_KEY = ['currentWeek', 'currentMonth', 'olderMessages'] as const;

@Component({
  selector: 'app-messages-modal',
  templateUrl: './messages-modal.page.html',
  styleUrls: ['./messages-modal.page.scss'],
})
export class MessagesModalPage implements OnInit {
  selectedStore: string;
  storeName: string;
  uniqueMsgTypes: { [t in typeof INCOMING_MSG_TYPES[number]]?: string } = {};
  filteredMessageTypes: { [t in typeof INCOMING_MSG_TYPES[number]]?: true };
  order: string[];
  selectedOrder: string[];
  filtered: string[];
  groupedByAge: GBA = {currentWeek: [], currentMonth: [], olderMessages: []};
  initialOpenGroup: keyof GBA;
  messages: { [msgID: string]: InboxMessage };
  oldestMsgDate: Date;
  newestMsgDate: Date;
  sentButIMAPFailed: { [msgID: string]: boolean } = {};
  otherTabs: { name?: string; value: string }[] = [];
  otherSenders: string[] = [];
  selectedDates: { start: Date; end: Date };
  expandedMessage: string;
  hide: { [what: string]: boolean } = {};
  changing: boolean;
  deleteDontAsk: boolean;
  deleteErrorDontAsk: boolean;
  dates: { [msgID: string]: Date } = {};
  dateStrings: { [msgID: string]: { d: string; t: string } } = {};
  previews: { [msgID: string]: string } = {};
  storeTabs$: Observable<StoreObject>;
  userStores$: Observable<IStore[]>;
  injectors = {};
  private storesInfoSubject = new BehaviorSubject<StoreObject>({order: [], stores: {}});
  private storesInfo: { stores: { [id: string]: StoreInfo }; order: string[] };


  private msgComponents = {};

  constructor(
    private firebase: FirebaseService,
    private msgService: MessagesService,
    private modalControl: ModalController,
    private alertControl: AlertController,
    private injector: Injector,
    private popControl: PopoverController,
    private readonly store: Store
  ) {
    this.storeTabs$ = this.storesInfoSubject.asObservable();
  }

  get storeOrder(): string[] {
    return this.storesInfo.order;
  }

  private static msgComponentLookUp(type: string) {
    switch (type) {
      case 'API-RESPONSE':
        return MsgApiResponseComponent;
      case 'AUTO_ORDER_RESULT':
        return MsgAoResponseComponent;
      case 'SIGN_UP':
        return MsgUserSignupNotificationComponent;
      default:
        return DefaultBodyComponent;
    }
  }

  private static setPreview(msg: InboxMessage) {
    let m = msg.payload.body;
    switch (msg.type) {
      case 'API-RESPONSE':
        m = 'Stock Update ' + (msg.payload.success ? 'Success' : 'Fail');
        break;
      case 'AUTO_ORDER_RESULT':
        m = 'Auto Order ' + (msg.payload.success ? 'Success' : 'Fail');
        break;
      case 'SIGN_UP':
        m = `${msg.payload.data.userName ? msg.payload.data.userName : msg.payload.data.email} signed up using your ` +
          'inviter link';
        break;
      case 'ACCESS_CHANGE':
        m = 'ACCESS CHANGE';
    }
    return m;
  }

  ngOnInit() {
    this.dates = {};
    this.previews = {};

    this.store.dispatch(getUserStores({ pageStoreDocument: 'messages-modal' }));
    this.userStores$ = this.store.select(selectUserStores);

    this.firebase.stores.subscribe((stores: StoreObject): void => {
      this.storesInfo = stores;
    });

    this.msgService.userMsgsSub.subscribe(msgObj => {
      if (msgObj.order.length === 0) {
        this.order = [];
        this.messages = {};
        this.oldestMsgDate = null;
        this.newestMsgDate = null;
        return;
      }
      let currentStoreAffected = false;
      for (const msgID of msgObj.order) {
        if (!this.previews.hasOwnProperty(msgID)) {
          const msg = msgObj.messages[msgID];
          const d: Date = msg.timestamp;
          this.dates[msgID] = d;
          this.dateStrings[msgID] = {
            d: `${leadingZero(d.getDate())}/${leadingZero(d.getMonth() + 1)}/${d.getFullYear()}`,
            t: `${leadingZero(d.getHours())}:${leadingZero(d.getMinutes())}:${leadingZero(d.getSeconds())}`
          };
          this.previews[msgID] = MessagesModalPage.setPreview(msg);

          if (!this.storesInfo.stores.hasOwnProperty(msg.sender) && !this.otherSenders.includes(msg.sender)) {
            this.otherTabs.push({value: msg.sender});
            this.otherSenders.push(msg.sender);
          }

          if (!msg.payload.success) {
            this.handleFailedMessage(msgID, msg);
          }

          if (!currentStoreAffected && this.selectedStore && msg.sender === this.selectedStore) {
            currentStoreAffected = true;
          }
        }
      }
      this.order = msgObj.order;
      this.messages = msgObj.messages;
      this.oldestMsgDate = new Date(this.messages[this.order[this.order.length - 1]].timestamp.getTime());
      this.newestMsgDate = new Date(this.messages[this.order[0]].timestamp.getTime());
      this.oldestMsgDate.setHours(0, 0, 0, 0);
      this.newestMsgDate.setHours(23, 59, 59, 999);

      if (!this.selectedStore && this.order) {
        this.selectStore(this.messages[this.order[0]].sender);
      } else if (currentStoreAffected) {
        this.groupMessages();
        this.filterMsgs();
      }
    });

    if (Object.keys(this.groupedByAge.olderMessages).length > RECOMMENDED_MAX) {
      this.deleteOldMessages(true).then();
    }
    const other = 'MANAGE_IT';
    if (!this.storesInfo.order.includes(other) || !this.storesInfo.stores[other]) {
      this.storesInfo.order.push(other);
      this.storesInfo.stores[other] = {name: other};
    }

  }

  async filterMsgTypes() {
    const value = this.filteredMessageTypes ? Object.keys(this.filteredMessageTypes) : null;
    const pc = await this.popControl.create({
      component: SelectPopoverComponent, event, componentProps: {
        title: 'Filter Messages', selection: this.uniqueMsgTypes, value, multiple: true,
        selectAll: true
      }
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (data && data.length && data.length !== objLen(this.uniqueMsgTypes)) {
      this.filteredMessageTypes = {};
      data.forEach((key) => (this.filteredMessageTypes[key] = true));
    } else {
      this.filteredMessageTypes = null;
    }
    this.filterMsgs();
  }

  async filterDates(event) {
    const mc = await this.popControl.create({
      component: DateRangeSelectorComponent,
      event,
      componentProps: {
        selectedDates: this.selectedDates ? {
          start: this.selectedDates.start, end: this.selectedDates.end
        } : null,
        minDate: this.oldestMsgDate, maxDate: this.newestMsgDate, isPopOver: true
      }
    });
    await mc.present();
    const {data} = await mc.onDidDismiss();

    if (data) {
      this.selectedDates = data;

      if (this.selectedDates.start) {
        this.selectedDates.start.setHours(0, 0, 0, 1);
      }
      if (this.selectedDates.end) {
        this.selectedDates.end.setHours(23, 59, 59, 999);
      }
    } else {
      this.selectedDates = null;
    }
    this.filterMsgs();
  }

  getStoreInfo = (storeID: string, isStore: boolean = true): StoreInfo | { value: string; name?: string } => {
    if (isStore) {
      return this.storesInfo.stores[storeID];
    } else {
      return this.otherTabs.filter((kv) => kv.value === storeID)[0];
    }
  };

  selectStore(storeID: string) {
    if (this.selectedStore === storeID) {
      return;
    }
    this.selectedDates = null;
    this.selectedStore = storeID;
    this.storeName = this.storesInfo.stores[storeID].name;
    this.groupMessages(storeID);
  }

  msg(ts: number): InboxMessage {
    return this.messages[ts];
  }

  close() {
    this.modalControl.dismiss().then();
  }

  expand(id: string) {
    const message = this.messages[id];
    this.expandedMessage = this.expandedMessage === id ? null : id;

    if (this.expandedMessage && !this.msgComponents[id]) {
      this.msgComponents[id] = MessagesModalPage.msgComponentLookUp(message.type);

      if (this.msgComponents[id]) {
        this.injectors[id] = Injector.create({
          providers: [{
            provide: 'INPUT', useValue: {
              msgID: id, message, getStoreInfo: this.getStoreInfo,
              timeStamp: `${this.dateStrings[id].t} ${this.dateStrings[id].d}`
            }
          }],
          parent: this.injector
        });
      }
    }
  }

  getExpansion(id: string) {
    return this.msgComponents[id];
  }

  async deleteOldMessages(recommendClean: boolean = false) {
    const deleteOld = async () => {
      const ac2 = await this.alertControl.create({
        header: 'Delete Old Message', subHeader: 'Are you sure you want to delete all messages older than one Month? ' +
          'This action cannot be undone', cssClass: 'custom-alert', buttons: ['Cancel', {text: 'Delete All', role: 'y'}]
      });
      await ac2.present();
      const response = await ac2.onDidDismiss();

      if (response.role === 'y') {
        for (const msgID of this.groupedByAge.olderMessages) {
          await this.deleteMsg(msgID);
        }
      }
    };

    this.changing = true;

    if (recommendClean) {
      const ac1 = await this.alertControl.create({
        header: 'Clear Old Message', subHeader: `You have more then ${RECOMMENDED_MAX} messages older then one month.`,
        message: 'We recommend clearing out the oldest ones to keep the inbox running smoothly.',
        cssClass: 'custom-alert',
        buttons: ['Cancel', {text: `Keep ${RECOMMENDED_MAX}`, role: 'keep'}, {text: 'Delete All', role: 'a'}]
      });
      await ac1.present();
      const {role} = await ac1.onDidDismiss();

      if (role === 'a') {
        await deleteOld();
      } else if (role === 'keep') {
        const ids = this.groupedByAge.olderMessages.slice(RECOMMENDED_MAX);

        for (const msgID of ids) {
          await this.deleteMsg(msgID);
        }
      }
    } else {
      await deleteOld();
    }
    this.changing = false;
  }

  async deleteMessage(msgID: string) {
    this.changing = true;

    if (this.messages[msgID].payload.success === false) {
      if (!this.deleteErrorDontAsk) {
        let ac = await this.alertControl.create({
          header: 'Delete Error Message',
          subHeader: 'WARNING: You are deleting an error message', message: 'Please make sure that you have resolved ' +
            'error messages before deleting them.<br><br>Either succesfully reatempt the action (if applicable) or ' +
            'ask Techodactyl Support to review the message for you.<br>He will delete it once the review is complete.',
          cssClass: ['custom-alert', 'warn'],
          inputs: [{type: 'checkbox', value: 'dontAsk', label: 'Dont ask again'}],
          buttons: ['Cancel', {text: 'Delete', role: 'y'}]
        });
        await ac.present();
        let response = await ac.onDidDismiss();

        if (response.role === 'y') {
          if (response.data.values && response.data.values.includes('dontAsk')) {
            ac = await this.alertControl.create({
              header: 'Stop Asking?', subHeader: 'I sure hope you know what you`re doing',
              cssClass: ['custom-alert', 'warn'], buttons: ['Cancel', {text: 'I Do', role: 'y'}]
            });
            await ac.present();
            response = await ac.onDidDismiss();

            if (response.role === 'y') {
              this.deleteErrorDontAsk = true;
              await this.deleteMsg(msgID);
            }
          } else {
            await this.deleteMsg(msgID);
          }
        }
      } else {
        await this.deleteMsg(msgID);
      }
    } else if (!this.deleteDontAsk) {
      const ac = await this.alertControl.create({
        header: 'Delete Message', subHeader: 'Are you sure you want to delete this message? This cannot be undone.',
        inputs: [{type: 'checkbox', value: 'dontAsk', label: 'Dont ask again'}],
        buttons: ['Cancel', {text: 'Delete', role: 'y'}]
      });
      await ac.present();
      const response = await ac.onDidDismiss();

      if (response.role === 'y') {
        if (response.data.values && response.data.values.includes('dontAsk')) {
          this.deleteDontAsk = true;
        }
        await this.deleteMsg(msgID);
      }
    } else {
      await this.deleteMsg(msgID);
    }
    this.changing = false;
  }

  private groupMessages(storeID: string = this.selectedStore) {
    this.groupedByAge = {currentWeek: [], currentMonth: [], olderMessages: []};
    this.selectedOrder = this.order.filter(a => this.messages[a].sender === storeID);

    const midnight = new Date();
    midnight.setHours(23, 59, 59, 999);

    const startOfWeek = firstDayOWeek(midnight, 'monday').getTime();
    const endOfWeek = lastDayOfWeek(midnight, 'sunday').getTime();
    const startOfMonth = firstDayOMonth(midnight).getTime();
    const endOfMonth = lastDayOMonth(midnight).getTime();
    this.groupedByAge = {currentWeek: [], currentMonth: [], olderMessages: []};
    this.uniqueMsgTypes = {};
    for (const msgID of this.selectedOrder) {
      const message = this.messages[msgID];
      const msgTs = message.timestamp.getTime();

      if (msgTs >= startOfWeek && msgTs <= endOfWeek) {
        this.groupedByAge.currentWeek.push(msgID);
      } else if (msgTs >= startOfMonth && msgTs <= endOfMonth) {
        this.groupedByAge.currentMonth.push(msgID);
      } else {
        this.groupedByAge.olderMessages.push(msgID);
      }

      for (const key of RANKED_GBA_KEY) {
        if (this.groupedByAge[key].length) {
          this.initialOpenGroup = key as keyof GBA;
          break;
        }
      }
    }
  }

  private filterMsgs() {
    const filters: ((msgID: string) => boolean)[] = [];

    if (this.filteredMessageTypes) {
      filters.push((msgID: string) => this.filteredMessageTypes[this.messages[msgID].type]);
    }
    if (this.selectedDates) {
      const min = this.selectedDates.start ? this.selectedDates.start.getTime() : 0;
      const max = this.selectedDates.end ? this.selectedDates.end.getTime() : Number.MAX_VALUE;
      filters.push((msgID: string) => {
        const t = this.messages[msgID].timestamp.getTime();
        return t >= min && t <= max;
      });
    }

    if (filters.length) {
      this.filtered = this.selectedOrder.filter(filters2Filter(filters));
    } else {
      this.filtered = null;
    }
  }

  private handleFailedMessage(msgID: string, msg: InboxMessage) {
    if (!msg.payload.hasOwnProperty('success') || msg.payload) {
      return;
    }
    switch (msg.type) {
      case 'AUTO_ORDER_RESULT':
        if (!msg.payload.data) {
          break;
        }

        for (const orderID of Object.keys(msg.payload.data)) {
          const r: AoResponse = msg.payload.data[orderID];

          if (!r.success && r.pos === 'AO_EMAIL_FAIL' && r.msg && r.msg.startsWith('IMAPIssueError')) {
            this.sentButIMAPFailed[msgID] = true;
            break;
          }
        }
        break;
    }
  }

  private async deleteMsg(msgId: string) {
    this.order.splice(this.order.indexOf(msgId), 1);
    this.selectedOrder.splice(this.selectedOrder.indexOf(msgId), 1);
    delete this.messages[msgId];
    this.groupMessages();
    await this.firebase.deleteMessage(msgId);
  }
}

// Todo: This should be moved to its own component and not placed within another
@Component({
  template: `
    <ion-grid>
      <ion-row>
        <ion-col><b>{{ title }}</b></ion-col>
      </ion-row>
      <ion-row>
        <ion-col>{{ body }}</ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class DefaultBodyComponent {
  body: string;
  title: string;

  constructor(
    @Inject('INPUT') private useValue: {
      id: string;
      message: InboxMessage;
      getStoreInfo: (storeID: string) => StoreInfo;
    },
  ) {
    switch (useValue.message.type) {
      case 'ACCESS_CHANGE':
        this.title = 'Important:';
        this.body = useValue.message.payload.body ?
          useValue.message.payload.body : 'Your application access has been updated.';
        break;
      default:
        this.title = `From ${useValue.getStoreInfo(useValue.message.sender).name}`;
        this.body = useValue.message.payload.body ? useValue.message.payload.body : '* no message body *';
    }
  }
}
