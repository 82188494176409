import {MatTooltipModule} from '@angular/material/tooltip';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResizableModule} from './modules-old/resizable/resizable.module';
import {SaveUserSettingsPageModule} from './modules-old/save-user-settings/save-user-settings.module';
import {SHARED_MODULE_COMPONENTS} from './components';
import {SHARED_MODULE_DIRECTIVE} from './directives';
import {MessagesModalPageModule} from './modules-old/messages-modal/messages-modal.module';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedIconsModule} from '../shared-icons/shared-icons.module';

@NgModule({
  declarations: [
    ...SHARED_MODULE_COMPONENTS,
    ...SHARED_MODULE_DIRECTIVE
  ],
    imports: [
        // AgGridAngular?
        CommonModule,
        FormsModule,
        IonicModule,
        MatTooltipModule,
        MessagesModalPageModule,
        ResizableModule,
        SaveUserSettingsPageModule,
        ReactiveFormsModule,
        SharedIconsModule,
    ],
  exports: [
    ResizableModule,
    MessagesModalPageModule,
    SaveUserSettingsPageModule,
    ...SHARED_MODULE_COMPONENTS,
    ...SHARED_MODULE_DIRECTIVE
  ]
})
export class SharedModule {
}
