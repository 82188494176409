/**
 * @enum HeaderMenuActions A list of actions that can be triggered on the menu.\
 *
 * @member editStart To enable editing on the grid - start editing.
 * @member editStop To disable editing on the grid - stop editing.
 * @member mainButton A button that does not trigger anything - for display only.
 * @member roar This action needs to be set up.
 * @member sortButton Main button for sorting menu.
 * @member sortAsc Sort a column ascending.
 * @member sortDesc Sort a column descending.
 * @member sortUnSort Un-sort a column.
 */
export enum HeaderMenuActions {
  /** @member editStart To enable editing on the grid - start editing. */
  editStart = 'edit-start',
  /** @member editStop To disable editing on the grid - stop editing. */
  editStop = 'edit-stop',
  hide = 'hide',
  /** @member mainButton A button that does not trigger anything - for display only. */
  mainButton = 'main-button',
  /** @member roar This action needs to be set up. */
  roar = 'roar',
  /** @member sortButton Main button for sorting menu. */
  sortButton = 'sort-main-button',
  /** @member sortAsc Sort a column ascending. */
  sortAsc = 'sort-asc',
  /** @member sortDesc Sort a column descending. */
  sortDesc = 'sort-desc',
  /** @member sortUnSort Un-sort a column. */
  sortUnSort = 'sort-un-sort',
  unHide = 'un-hide'
}
