import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FirebaseError } from '@angular/fire/app';
import {Message, UserAccess} from '../models-old/datastructures';
import {AccessFlags, RuleHumanID, RuleInfo, ruleStructure} from '../models-old/utils-old/rule-structure';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {StandardAlertsService} from './standard-alerts.service';

@Injectable({
  providedIn: 'root'
})
export class FireAuthService {

  private authStateSub: Subscription;
  private userAccessSubject: BehaviorSubject<UserAccess> = new BehaviorSubject<UserAccess>(null);
  private readonly userIDSubject: BehaviorSubject<string>;
  private readonly preLogoutCallbacks: { [name: string]: { call: () => void | Promise<void>; promise?: boolean } } = {};
  private readonly ruleFlags: BehaviorSubject<{ [storeID: string]: boolean[] }> = new BehaviorSubject({});

  private authTime: Date;

  constructor(
    private fireAuth: AngularFireAuth,
    private af: AngularFirestore,
    private stdAlert: StandardAlertsService,
    private alertControl: AlertController,
    private toastControl: ToastController,
    private router: Router,
  ) {
    this.userIDSubject = new BehaviorSubject<string>(null);
    this.subscribe();
  }

  get userIDSub(): Observable<string> {
    return this.userIDSubject.asObservable();
  }

  get userAccess(): Observable<UserAccess> {
    return this.userAccessSubject.asObservable();
  }

  hasAccess(storeID: string, ruleIdentity: { ruleIndex?: number; ruleID?: RuleHumanID | AccessFlags}): true | RuleInfo {
    const hasIndex = ruleIdentity.hasOwnProperty('ruleIndex');
    const hasID = ruleIdentity.hasOwnProperty('ruleID');
    let ruleIndex: number = null;

    console.log(`Rule ID: ${ruleIdentity.ruleIndex}`);

    if (hasID) {
      if (AccessFlags[ruleIdentity.ruleID]) {
        if (this.userAccessSubject.value[ruleIdentity.ruleID]) {
          return true;
        } else if (this.userAccessSubject.value.hasOwnProperty(ruleIdentity.ruleID)) {
          console.warn(`User should not have an empty access flag ${AccessFlags[ruleIdentity.ruleID]}`);
        }
        return AccessFlags[ruleIdentity.ruleID];
      }
      if (ruleStructure.dictionary.hasOwnProperty(ruleIdentity.ruleID)) {
        ruleIndex = ruleStructure.index.find((rule, index) => rule.humanID === ruleIdentity.ruleID).index;
      }
    }

    if (ruleIndex !== null) {
      if (hasIndex && ruleIndex !== ruleIdentity.ruleIndex) {
        throw Error('Provided ruleIndex and ruleID do not point to the same rule');
      }
    } else if (hasIndex) {
      ruleIndex = ruleIdentity.ruleIndex;

      if (ruleIndex < 0 || ruleIndex >= ruleStructure.index.length) {
        throw Error(`Invalid ruleIndex ${ruleIndex}. No such rule index exists.`);
      }
    } else {
      throw Error('WTF');
    }

    if (this.ruleFlags.value[storeID] && this.ruleFlags.value[storeID][ruleIndex]) {
      return true;
    } else {
      return ruleStructure.index[ruleIndex];
    }
  }

  async pause() {
    const uID = await this.userIDSub.pipe(take(1)).toPromise();

    if (!uID) {
      this.authStateSub.unsubscribe();
      this.authStateSub = null;
    }
  }

  async unPause() {
    const uID = await this.userIDSub.pipe(take(1)).toPromise();

    if (!uID && !this.authStateSub) {
      this.subscribe();
    }
  }

  setLogoutCallback(name: string, callback: () => void | Promise<void>, promise?: boolean): boolean {

    if (this.preLogoutCallbacks.hasOwnProperty(name)) { return false; }
    this.preLogoutCallbacks[name] = {call: callback};

    if (promise)  { this.preLogoutCallbacks[name].promise = promise; }
    return true;
  }

  async login(email: string, password: string) {
    if (email && password) {
      const ac = await this.alertControl.create({header: 'Verifying Credentials', subHeader: 'Please Wait',
        cssClass: 'custom-alert', backdropDismiss: false});
      await ac.present();
      this.fireAuth.signInWithEmailAndPassword(email, password).then(r => {
        this.unPause();
        ac.dismiss();
        if (r.user) {
          this.router.navigate(['home']).then(async () => {
            const tc = await this.toastControl.create({
              header: 'Welcome To ManageIt', duration: 1600, position: 'top', cssClass: 'happy-toast'
            });
            await tc.present();
          });
        }
      }).catch((e: FirebaseError) => {
        ac.dismiss();
        let header: string;
        let subHeader: string;
        let message: string;

        switch (e.code) {
          case 'auth/user-not-found':
            header = 'User Not found';
            subHeader = 'No user found with the provided email address.';
            message = 'Please check you have entered the correct address or that your account has not been deleted.';
            break;
          case 'auth/invalid-email':
            header = 'Invalid Email Address';
            subHeader = 'The address provided is not a valid email address.';
            break;
          case 'auth/wrong-password':
            header = 'Password Incorrect';
            subHeader = 'The password you entered is incorrect.';
            break;
          default:
            header = 'Unknown Error Occurred';
            subHeader = 'I didnt expect this. Please inform Techodactyl Support';
            message= 'Error code: ' + e.code;
            break;
        }
        this.alertControl.create({header, subHeader, message, cssClass: ['custom-alert', 'error']})
          .then(ac2 => ac2.present().then());
      });
    }
  }

  async logout(ask: boolean = true, gotToLogin: boolean = true) {
    const userID = await this.userIDSub.pipe(take(1)).toPromise();

    if (!userID) { return; }

    if (ask) {
      const ac = await this.alertControl.create({
        header: 'Sign Out',
        subHeader: 'Are you sure you want to sign out?',
        message: 'Very soon signing out will clear any data not saved to the cloud.',
        cssClass: ['custom-alert', 'warn'],
        buttons: ['Cancel', {text: 'Sign Out', role: 'y'}]
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();

      if (role !== 'y') {
        return;
      }
    }

    // console.clear();
    console.log(`Logging out current user: ${userID}`);
    this.setUserAccessFlags(null);
    this.userIDSubject.next(null);
    const callBackNames = Object.keys(this.preLogoutCallbacks);

    const process = () => {
      if (callBackNames.length === 0) {
        this.fireAuth.signOut().then(() => {
          if (gotToLogin) {
            this.router.navigate(['login'], {replaceUrl: true}).then(() => console.log('no User logged in'));
          }
        });
      } else {
        const name = callBackNames.pop();
        const cbPack = this.preLogoutCallbacks[name];
        console.log(`Logout Callback: ${name}...`);

        if (cbPack.promise) {
          (cbPack.call as () => Promise<void>)().then(() => {
            delete this.preLogoutCallbacks[name];
            process();
          });
        } else {
          cbPack.call();
          delete this.preLogoutCallbacks[name];
          process();
        }
      }
    };
    process();
    window.location.reload();

  }

  async resetPwd(email: string, storeID?: string): Promise<void> {
    const userID = this.userIDSubject.value;

    const success = async () => {
      const ac = await this.alertControl.create({header: 'Email Sent', subHeader: 'A password reset email has been ' +
          `sent to ${email}.`, cssClass: 'custom-alert', buttons: ['ok']});
      await ac.present();
      await ac.onDidDismiss();
    };

    const uncertainEmail = async () => {
      try {
        await this.fireAuth.sendPasswordResetEmail(email);
        await success();
      } catch (error) {
        if ((error as FirebaseError).code === 'auth/user-not-found') {
          await this.stdAlert.errorAlert({type: 'USER-NOT-FOUND'});
        } else {
          await this.stdAlert.errorAlert({
            subHeader: `Error requesting password reset on ${error}`, message: `"${error.message}"`
          });
        }
      }
    };

    if (userID) {
      if (email !== userID) {
        if (storeID) {
          if (this.hasAccess(storeID, {ruleID: 'a.'}) === true) {
            await uncertainEmail();
          } else {
            await this.stdAlert.errorAlert({subHeader: 'You do not have permission to request an email reset ' +
                `for ${email}`, type: 'PERMISSION'});
          }
        }
      } else {
        await this.fireAuth.sendPasswordResetEmail(email);
        await success();
      }
    } else {
      await uncertainEmail();
    }
  }

  async accessChange(msg: Message) {

    if (msg.type !== 'ACCESS_CHANGE' || (this.authTime && msg.timestamp.getTime() < this.authTime.getTime())) {
      return;
    }
    const userID = await this.userIDSub.pipe(take(1)).toPromise();
    this.af.doc(`/user_access/${userID}`).get().toPromise()
      .then((uaD) => this.setUserAccessFlags(uaD.data() as UserAccess));
    const ac = await this.alertControl.create({
      header: 'Your Access Permissions Have Been Updated', message: 'If you think this is a mistake please contact ' +
        'your admin.', cssClass: 'custom-alert', buttons: ['ok']
    });
    // const ac = await this.alertControl.create({
    //   header: 'User Access Updates', subHeader: 'Your access to one or more stores has been updated',
    //   message: 'Inorder for these changes to take affect you will need to sign back in',
    //   cssClass: ['custom-alert', 'warn'], buttons: ['ok'], backdropDismiss: false
    // });
    await ac.present();
    await ac.onDidDismiss();
  }

  private subscribe() {
    // this.authStateSubscription = this.fireAuth.authState.subscribe(user => {
    // this.fireAuth.idToken.subscribe(a => {
    //
    // });
    this.authStateSub = this.fireAuth.authState.subscribe(user => {
      if (user) {
        // if (user.email === 'claydenburger@gmail.com') {
        this.userIDSubject.next(user.email);
        this.af.doc(`/user_access/${user.email}`).get().toPromise()
          .then((uaD) => this.setUserAccessFlags(uaD.data() as UserAccess));
        // } else {
        //   const alert = () => {
        //     this.alertControl.create({
        //       header: 'Lockdown For Cloud Security Update',
        //       message: '* This should only take a few hours<br>* Once complete ManageIt will be incomparably more ' +
        //         'secure<br>* This is the biggest step towards ALPHA we have had<br>* Side bonus: adding manager ' +
        //         'control of staff access and adding or removing staff will now be easy<br><br>Thanks<br>Techodactyl Support',
        //       cssClass: ['custom-alert', 'warn'], backdropDismiss: false
        //     }).then(ac => ac.present().then(() => ac.onDidDismiss().then(() => alert())));
        //   };
        //   alert();
        // }
        // user.getIdTokenResult().then(r => {
        //   const userAccess = {features: r.claims.features, storeList: r.claims.storeList, stores: r.claims.stores};
        //   this.setUserAccessFlags(userAccess);
        //   this.authTime = new Date(Date.parse(r.authTime));
        //   this.userAccessSubject.next(userAccess);
        //   console.log('got token', r);
        // });
      } else {
        console.log('null');
        this.userIDSubject.next(null);
      }
    }, error => console.log('Well damn'));
  }

  private setUserAccessFlags(access: UserAccess) {
    const storeFlags: { [storeID: string]: boolean[] } = {};

    if (access) {
      access.storeList.forEach((storeID) => {
        storeFlags[storeID] = ruleStructure.index.map((rule, index) =>
          rule.index === index && Math.floor((access.stores[storeID] / Math.pow(2, index)) % 2) === 1);
      });
    }
    this.ruleFlags.next(storeFlags);
    this.userAccessSubject.next(access);
  }
}
