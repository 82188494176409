import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as CoreActions from './core.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {from, of} from 'rxjs';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {IUser} from '../../../shared-utilities/models-old/user/IUser';
import {StoreObject} from '../../../shared-utilities/models-old/store/store-object';

@Injectable()
export class CoreEffects {

  // ====================================================================================================
  // Get User
  // ====================================================================================================

  // '[Core] Get User'
  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUser),
    mergeMap(() =>
      from(this.firebaseService.userObj)
        .pipe(
          map((user: IUser) => CoreActions.getUserSuccess({user})),
          catchError((error) => of(CoreActions.getUserFailure({error})))
        )
    )
  ));

  // ====================================================================================================
  // Get User Stores
  // ====================================================================================================

  // '[Core] Get User Stores'
  getUserStores$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActions.getUserStores),
    mergeMap(({pageStoreDocument}) =>
      this.firebaseService.pageStores(pageStoreDocument)
        .pipe(
          map((storeObject: StoreObject) => CoreActions.getUserStoresSuccess({storeObject})),
          catchError((error) => of(CoreActions.getUserStoresFailure({error})))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
  ) {
  }
}
