import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import * as CoreActions from './core.actions';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {IUser} from '../../../shared-utilities/models-old/user/IUser';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {GridUtils} from '../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {getModuleNameFromRouterModule} from '../../../shared-utils/navigation-url.utils';

export interface CoreState extends IInitialState {
  // Modules --------------------------------
  isModuleAdminInfoLoading: boolean;
  isModuleAdminInternalLoading: boolean;
  isModuleAutoOrderingLoading: boolean;
  isModuleBarcodeScannerLoading: boolean;
  isModuleCoreLoading: boolean;
  isModuleDashboardLoading: boolean;
  isModuleHomeLoading: boolean;
  isModuleLoginLoading: boolean;
  isModuleNgpReportLoading: boolean;
  isModuleShelfTalkersLoading: boolean;
  isModuleSignupLoading: boolean;
  isModuleStockLoading: boolean;
  isModuleStockMatchLoading: boolean;
  isModuleStockUpdatesLoading: boolean;
  isModuleTestingLoading: boolean;
  isModuleUserAccessLoading: boolean;
  // User -----------------------------------
  isUserLoading: boolean;
  user: IUser;
  ita: boolean
  // User Stores ----------------------------
  isUserStoresLoading: boolean;
  userStores: IStore[];
  userSelectedStore: IStore;
  currentSelectedPage: INavigationPage;
}

export const initialCoreState: CoreState = {
  // Initial --------------------------------
  errors: [],
  // Modules --------------------------------
  isModuleAdminInfoLoading: false,
  isModuleAdminInternalLoading: false,
  isModuleAutoOrderingLoading: false,
  isModuleBarcodeScannerLoading: false,
  isModuleCoreLoading: false,
  isModuleDashboardLoading: false,
  isModuleHomeLoading: false,
  isModuleLoginLoading: false,
  isModuleNgpReportLoading: false,
  isModuleShelfTalkersLoading: false,
  isModuleSignupLoading: false,
  isModuleStockLoading: false,
  isModuleStockMatchLoading: false,
  isModuleStockUpdatesLoading: false,
  isModuleTestingLoading: false,
  isModuleUserAccessLoading: false,
  // User -----------------------------------
  isUserLoading: false,
  user: null,
  ita: false,
  // User Stores ----------------------------
  isUserStoresLoading: false,
  userStores: null,
  userSelectedStore: null,
  currentSelectedPage: {currentPage: 'home', currentTab: null},
};

const createCoreReducer: ActionReducer<CoreState> = createReducer(
  initialCoreState,
  // ====================================================================================================
  // Get User
  // ====================================================================================================
  on(CoreActions.getUser, (state: CoreState) => ({
    ...state,
    isUserLoading: true,
    user: null
  })),
  on(CoreActions.getUserSuccess, (state: CoreState, {user}) => ({
    ...state,
    isUserLoading: false,
    user
  })),
  on(CoreActions.getUserFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserLoading: false,
    user: null,
    errors: [...state.errors, error]
  })),
  on(CoreActions.setIsUserLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserLoading: isLoading
  })),
  // ====================================================================================================
  // Set ITA (Is Techo Admin)
  // ====================================================================================================
  on(CoreActions.setITA, (state: CoreState, {ita}) => ({
    ...state,
    ita
  })),
  // ====================================================================================================
  // Get User Stores
  // ====================================================================================================
  on(CoreActions.getUserStores, (state: CoreState, {pageStoreDocument}) => ({
    ...state,
    isUserStoresLoading: true,
    userStores: null
  })),
  on(CoreActions.getUserStoresSuccess, (state: CoreState, {storeObject}) => {
    const userStores = GridUtils.mapStoreObjectToStore(storeObject);
    return {
      ...state,
      isUserStoresLoading: false,
      userStores,
      userSelectedStore: userStores.length > 0 ? userStores[0] : null
    };
  }),
  on(CoreActions.getUserStoresFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserStoresLoading: false,
    userStores: null,
    errors: [...state.errors, error]
  })),
  on(CoreActions.setIsUserStoresLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserStoresLoading: isLoading
  })),
  // ====================================================================================================
  // Set User Selected Store
  // ====================================================================================================
  on(CoreActions.setUserSelectedStore, (state: CoreState, {selectedStore}) => ({
    ...state,
    userSelectedStore: selectedStore
  })),
  // ====================================================================================================
  // Set Use Last Visited Page
  // ====================================================================================================
  on(CoreActions.setCurrentPageAndTab, (state: CoreState, {currentSelectedPage}) => ({
    ...state,
    currentSelectedPage
  })),
  // ====================================================================================================
  // Set Is Module Loading
  // ====================================================================================================
  on(CoreActions.setIsModuleLoading, (state: CoreState, {event, isLoading, instance}) => {
    const module = getModuleNameFromRouterModule(event);
    return {
      ...state,
      [`isModule${module}Loading`]: isLoading
    }
  }),
);

export const coreReducer = (state: CoreState, action: Action): CoreState => createCoreReducer(state, action);
