import {createFeatureSelector, createSelector} from '@ngrx/store';
import {NGPReportState} from './ngp-report.reducer';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {selectSelectedUserStores} from '../../feature-core/store/core.selectors';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {
  IDepAndSubDepByStoreId
} from '../../../shared-utilities/models-old/ngp-report-grid/departments-and-sub-departments';

export const selectNGPState = createFeatureSelector<NGPReportState>('ngp-report');

// ====================================================================================================
// Set Filters and Tools
// ====================================================================================================
export const selectFiltersAndTools = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.filtersAndTools
);

// ====================================================================================================
// Get NGP Reports
// ===============================X=====================================================================
export const selectNGPReports = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.ngpReports
);

export const selectNGPReportsFiltered = createSelector(
  selectNGPReports,
  selectFiltersAndTools,
  selectSelectedUserStores,
  (allNGPReports: { [storeID: string]: NGPReport[] }, filtersAndTools: FiltersAndTools, store: IStore): NGPReport[] => {
    const storeNGPReports = allNGPReports[store.storeID] || [];
    if (!filtersAndTools) {
      return storeNGPReports || [];
    }
    const ngpReports: NGPReport[] = [...storeNGPReports];
    let copyNGPReports: NGPReport[] = [...storeNGPReports];

    /** Negative Values Only ------------------------------------------------------------------ */
    if (filtersAndTools.isGpDifferenceNegativeOn && !filtersAndTools.isGpDifferencePositiveOn) {
      copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.diffGP < 0);
    }

    /** Positive Values Only ------------------------------------------------------------------ */
    if (filtersAndTools.isGpDifferencePositiveOn && !filtersAndTools.isGpDifferenceNegativeOn) {
      copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.diffGP >= 0);
    }

    /** Show Preview ----------------------------------------------------------------------------- */
    if (filtersAndTools.isGpDifferencePositiveOn && filtersAndTools.isGpDifferenceNegativeOn) {
      if (filtersAndTools.showSubmissionPreview) {
        const copyEditedNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
        const copySelectedNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isSelected && !row.isEdited);
        copyNGPReports = copyEditedNGPReports.concat(copySelectedNGPReports);
      } else if (filtersAndTools.isEditedItemsOn) {
        copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
      } else {
        copyNGPReports = ngpReports;
      }
    }

    /** No Values ----------------------------------------------------------------------------- */
    if (!filtersAndTools.isGpDifferencePositiveOn && !filtersAndTools.isGpDifferenceNegativeOn) {
      copyNGPReports = [];
    }

    return copyNGPReports;
  }
);

// ====================================================================================================
// Set Grid Column Definitions
// ====================================================================================================
export const selectNGPReportGridColDefs = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.ngpReportGridColDefs
);

// ====================================================================================================
// Get Grid Menu Data
// ====================================================================================================
export const selectNGPReportsMenuData = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.headerMenuData
);

export const selectNGPReportsMenuDataByStoreId = createSelector(
  selectNGPReportsMenuData,
  selectSelectedUserStores,
  (headerMenuData: StoreHeaderMenuData, selectedStore: IStore) => {
    if (!selectedStore?.storeID || !headerMenuData[selectedStore.storeID]) {
      return {} as StoreHeaderMenuData;
    } else {
      return headerMenuData[selectedStore.storeID];
    }
  }
);

// ====================================================================================================
// Get Sorted and Filtered NGPReport
// ====================================================================================================
export const selectNGPReportsFilteredWithSort = createSelector(
  selectNGPReportsFiltered,
  selectNGPReportsMenuData,
  (ngpReports: NGPReport[], headerMenuData: StoreHeaderMenuData) => {
    let found = '';
    Object.keys(headerMenuData).forEach((key) => {
      if (headerMenuData[key]?.sortType !== null && headerMenuData[key]?.sortType !== false) {
        found = key;
      }
    });
    if (found !== '' && headerMenuData) {
      switch (headerMenuData[found].sortType) {
        case 'asc':
          ngpReports.sort((a, b) => a[found] > b[found] ? 1 : -1);
          break;
        case 'desc':
          ngpReports.sort((a, b) => a[found] < b[found] ? 1 : -1);
          break;
        default:
          break;
      }
      return ngpReports;
    } else {
      return ngpReports;
    }

  }
);
// ====================================================================================================
// Get Is Ngp Reports Loading
// ====================================================================================================
export const selectIsNGPReportsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isNGPReportsLoading
);

// ====================================================================================================
// Get Visible Fields For NGP Reports
// ====================================================================================================
export const selectNGPVisibleFields = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.visibleFields
);

// ====================================================================================================
// Get Line Colours For Selected Store
// ====================================================================================================
export const selectLineColours = createSelector(
  selectNGPState,
  selectSelectedUserStores,
  (ngpState: NGPReportState, selectedStore: IStore) => ngpState.lineColours ? ngpState.lineColours[selectedStore.storeID] : null
);

//===============================================================================================================
// Get Store Departments
//===============================================================================================================
export const selectIsStoreDepartmentsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isDepartmentsLoading
);

export const selectAllStoreDepartments = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.departments
);

export const selectDepartmentsForSelectedStore = createSelector(
  selectAllStoreDepartments,
  selectSelectedUserStores,
  (departments: IDepAndSubDepByStoreId, selectedUserStore: IStore) => {
    if (!selectedUserStore || !departments) {
      return {};
    }
    return departments[selectedUserStore.storeID] || {};
  }
);

//===============================================================================================================
// Get Store Suppliers
//===============================================================================================================

export const selectAllStoresSuppliers = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.storeSuppliers
);

export const selectSuppliersByUserSelectedStore = createSelector(
  selectNGPState,
  selectSelectedUserStores,
  (state: NGPReportState, selectedUserStore: IStore) => state.storeSuppliers[selectedUserStore.storeID] || {}
);
