import {createAction, props} from '@ngrx/store';
import {IUser} from '../../../shared-utilities/models-old/user/IUser';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {StoreObject} from '../../../shared-utilities/models-old/store/store-object';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {RouterEvent} from '@angular/router';

// ====================================================================================================
// Get User
// ====================================================================================================

export const getUser = createAction(
  '[Core] Get User'
);

export const getUserSuccess = createAction(
  '[Core] Get User Success',
  props<{ user: IUser }>()
);

export const getUserFailure = createAction(
  '[Core] Get User Failure',
  props<{ error: IError }>()
);

export const setIsUserLoading = createAction(
  '[Core] Set Is User Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Set ITA (Is Techo Admin)
// ====================================================================================================
export const setITA = createAction(
  '[Core] Set ITA',
  props<{ ita: boolean }>()
);

// ====================================================================================================
// Get User Stores
// ====================================================================================================
export const getUserStores = createAction(
  '[Core] Get User Stores',
  props<{ pageStoreDocument: string }>()
);

export const getUserStoresSuccess = createAction(
  '[Core] Get User Stores Success',
  props<{ storeObject: StoreObject }>()
);

export const getUserStoresFailure = createAction(
  '[Core] Get User Stores Failure',
  props<{ error: IError }>()
);

export const setIsUserStoresLoading = createAction(
  '[Core] Set Is User Stores Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Set User Selected Store
// ====================================================================================================
export const setUserSelectedStore = createAction(
  '[Core] Set User Selected Store',
  props<{ selectedStore: IStore }>()
);

// ====================================================================================================
// Set User Last Visited Page
// ====================================================================================================
export const setCurrentPageAndTab = createAction(
  '[Core] Set User Last Visited Page',
  props<{ currentSelectedPage: INavigationPage }>()
);

// ====================================================================================================
// Set Is Module Loading
// ====================================================================================================
export const setIsModuleLoading = createAction(
  '[Core] Set Is Module Loading',
  props<{ event: RouterEvent; isLoading: boolean; instance: string }>()
);

