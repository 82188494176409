import {createFeatureSelector, createSelector} from '@ngrx/store';
import {selectCurrentPageAndTab} from '../../../features-as-modules/feature-core/store/core.selectors';
import {
  selectNGPReportsMenuDataByStoreId,
  selectNGPVisibleFields
} from '../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {
  HeaderMenuColumnData,
  StoreHeaderMenuData
} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {SharedGridState} from './shared-grid.reducer';

export const selectSharedGridState = createFeatureSelector<SharedGridState>('shared-grid');

//===============================================================================================================
// Export to CSV
//===============================================================================================================
export const selectExportToCSVSettings = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.exportToCSVSettings
);

//===============================================================================================================
// Get Table Nav Settings
//===============================================================================================================

export const selectGridHeaderMenuDataByCurrentPage = createSelector(
  selectCurrentPageAndTab,
  selectNGPReportsMenuDataByStoreId,
  (
    navigation: { currentSelectedPage: INavigationPage },
    headerMenuData: StoreHeaderMenuData | {
      [p: string]: HeaderMenuColumnData;
    }) => {
    const currentPage = navigation.currentSelectedPage.currentPage;
    switch (currentPage) {
      case 'ngp-report':
        return headerMenuData;
      default:
        return {};
    }
  }
);

export const selectSharedGridVisibleFieldsByCurrentPage = createSelector(
  selectCurrentPageAndTab,
  selectNGPVisibleFields,
  (navigation: { currentSelectedPage: INavigationPage }, visible: { [p: string]: boolean }) => {
    const currentPage = navigation.currentSelectedPage.currentPage;
    switch (currentPage) {
      case 'ngp-report':
        return visible;
      default:
        return {};
    }
  }
);

export const selectTableNavSettings = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.tableNavSettings
);

